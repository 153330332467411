import React from "react"
import classes from "./BlocksWithStars.module.scss"
import SvgIcon from "src/components/SvgIcon"
import Clutch from "src/assets/icons/clutch.svg"
import Upwork from "src/assets/icons/upwork.svg"
import ArrowNext from "src/assets/icons/arrow-next.svg"
import Star from "src/assets/icons/star-new.svg"

const BlocksWithStars = ({ titleSection, list }) => {
  const returnStars = starsCount => {
    let stars = []
    for (let i = 0; i < starsCount; i++) {
      stars.push(<SvgIcon icon={Star} className={classes.star} key={i} />)
    }
    return stars
  }
  return (
    <div className={`${classes.starsSection} twoColumnBlock`}>
      <div className="leftColumn">
        <h3 className={classes.title}>{titleSection}</h3>
      </div>
      <div className="rightColumn">
        <div className={classes.cardsContainer}>
          {list.map(item => (
            <a
              href={item.link}
              key={item.id}
              rel="noopener noreferrer"
              className={classes.cardWrapperItem}
              target="_blank"
            >
              <div className={classes.platformIconWrapper}>
                {item.platform === "clutch" && (
                  <SvgIcon icon={Clutch} className={classes.platformIcon} />
                )}
                {item.platform === "upwork" && (
                  <SvgIcon icon={Upwork} className={classes.platformIcon} />
                )}
              </div>
              <div className={classes.bottom}>
                <div className={classes.reviews}>
                  <div className={classes.starsWrapper}>
                    {returnStars(item.stars)}
                  </div>
                  <p className={classes.reviewsText}>{item.reviews} reviews</p>
                </div>
                <div className={classes.cardMore}>
                  <p className={classes.cardMoreText}>More</p>
                  <SvgIcon icon={ArrowNext} className={classes.cardMoreIcon} />
                </div>
              </div>
            </a>
          ))}
        </div>
      </div>
    </div>
  )
}

export default BlocksWithStars
