import React, { memo } from "react"
import classes from "./AgencyPage.module.scss"
import PageNameAndImage from "../layout/PageNameAndImage/PageNameAndImage"
import ExperienceSection from "../layout/ExperienceSection/ExperienceSection"
import BlocksWithStars from "../layout/BlocksWithStars/BlocksWithStars"
import TickerRun from "../layout/TickerRun/TickerRun"
import OurTeamCarousel from "../Carousel/OurTeamCarousel/OurTeamCarousel"
import AgencyCarousel from "../Carousel/AgencyCarousel/AgencyCarousel"

const slides = [
  "aboutUs/image-0.jpg",
  "aboutUs/image-1.jpg",
  "aboutUs/image-2.jpg",
  "aboutUs/image-3.jpg",
  "aboutUs/image-4.jpg",
  "aboutUs/image-5.jpg",
  "aboutUs/image-6.jpg",
]

const AgencyPage = () => {
  return (
    <>
      <PageNameAndImage
        name="Agency"
        imagePath="company-photo.jpg"
        isShowIcon={false}
      />
      <div className="container">
        <div className="twoColumnBlock">
          <div className="leftColumn">
            <h3 className={classes.agencyTitle}>Who We are?</h3>
          </div>
          <div className="rightColumn">
            <h5 className={classes.agencySubtitle}>
              Drum'N'Code is engaged in the development of new turnkey
              technologies.
            </h5>
            <div className={classes.agencyTextWrapper}>
              <p className={classes.agencyText}>
                Drum’N’Code is a software development company and IT outsourcing
                service provider, headquartered in Zaporizhzhia and delivering
                high-end solutions to precise specifications since 2013. We
                specialize in web and mobile development and committed to the
                creation of solid turnkey solutions for our customers.
              </p>
              <p className={classes.agencyText}>
                Most of the projects we are creating from scratch, implementing
                not only the program code of individual modules and design, but
                also architecture. Our team contains more than 80 professionals
                and it's constantly growing. We select the team thoroughly and
                pay great attention to the development of skills and
                communication in the team, which as a result gives the effect of
                synergy.
              </p>
              <p className={classes.agencyText}>
                Today we have managed to bring together a great team of
                professionals in the field of web and mobile development, design
                and management. Now we are a full-cycle development company that
                creates advanced products and works on complex projects that
                require highly qualified experts and non-standard solutions.
              </p>
            </div>
            <AgencyCarousel
              agency={slides}
              className={classes.agencyCarousel}
            />
          </div>
        </div>

        <div className={`${classes.agencyContentWrapper} twoColumnBlock`}>
          <div className="leftColumn">
            <h3 className={classes.agencyTitle}>Our Mission</h3>
          </div>
          <div className="rightColumn">
            <div className={classes.agencyTextWrapper}>
              <p className={classes.agencyText}>
                Our main mission is to deliver a quality product on time for our
                customers. We help companies and startups, whose business is not
                related to web development, with our competence, bringing
                additional value with questions, suggestions and improvements to
                their product or system. Our work is based on high skills,
                dedication, partnership and flexibility. We understand customer
                needs and deliver effective solutions and are always ready to be
                responsible for the result.
              </p>
            </div>
          </div>
        </div>

        <ExperienceSection
          title="Interesting Data"
          list={JSON.parse(`[
            { "id": 1, "counter": 8, "subtitle": "Years of Experience" },
            { "id": 2, "counter": 86, "subtitle": "Full-Time Engineers" },
            { "id": 3, "counter": 172, "subtitle": "Successful Projects" },
            { "id": 4, "counter": 115, "subtitle": "Customers Worldwide" }
          ]`)}
        />
      </div>

      <OurTeamCarousel />

      <div className="container">
        <BlocksWithStars
          titleSection="What Our Clients Say"
          list={JSON.parse(`[
            {
              "id": 1,
              "platform": "clutch",
              "stars": 5,
              "reviews": 6,
              "link": "https://clutch.co/profile/drumncode"
            },
            {
              "id": 2,
              "platform": "upwork",
              "stars": 5,
              "reviews": 38,
              "link": "https://www.upwork.com/ag/drumncode/"
            }
          ]`)}
        />
      </div>
      <TickerRun text="Get in Touch" yellow={false} stringTicker />
    </>
  )
}

AgencyPage.propTypes = {}

export default memo(AgencyPage)
